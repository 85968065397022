import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/baby/diaper-rash/baby-diaperrash-hero.webp";
import productInfoIcon from "images/icons/icon-diaper-rash.png";
import productInfoImg from "images/baby/diaper-rash/diaper-rash-data-visual.webp";
import img1 from "images/baby/diaper-rash/diaper-rash-cream.webp";
import img2 from "images/baby/diaper-rash/diaper-rash-paste.webp";
import img3 from "images/baby/diaper-rash/section-ointment-features.webp";
import img4 from "images/callouts/cout-footer-aquaphor-baby.webp";
import img5 from "images/baby/general-care/Eczema_Regimen-Callout_Footer.webp";
import img6 from "images/baby/general-care/Baby-Resources-Callout_Footer.webp";

import "./diaperrash.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function DiaperRashPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-baby-diaperrash"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Diaper Rash"
        pageDescription="Learn about Aquaphor therapeutic care for diaper rash"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#363084"
            headText="DIAPER RASH"
            infoText="Help soothe mild to severe diaper rash with a range of treatments"
            bodySpanText=""
            bodyText="Because when a baby cries, everyone’s heart hurts."
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Diaper icon"
            productInfoImg={productInfoImg}
            productInfoImgAlt="Aquaphor® baby healing cream products"
          >
            <h5 className="metioriteblue-text">
              Recommend Aquaphor<sup>&reg;</sup> Baby to calm diaper rash
              discomfort
            </h5>
            <p>
              From prevention to treatment, we've got diaper rash covered.
              Discover a range of barrier protection to help soothe babies'
              bottoms.
            </p>
            <ul className="metiorite-bullets">
              <li>
                <span>
                  <span className="font-semi-bold">
                    Helps relieve discomfort
                  </span>{" "}
                  in one application
                </span>
              </li>
              <li>
                <span>
                  Aquaphor<sup>&reg;</sup> Baby Diaper Rash Paste{" "}
                  <span className="font-semi-bold">
                    {" "}
                    improves rash in as little as 24 hours<sup>1</sup>
                  </span>
                </span>
              </li>
              <li>
                <span>
                  <span className="font-semi-bold">Two formulations</span> to
                  cover a range of diaper rashes
                </span>
              </li>
            </ul>
          </CategoryDetails>
        </section>
        <section className="callouts-container gradient-bg gray-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Aquaphor<sup>&reg;</sup> Baby 3-in-1 Diaper Rash Cream`}
                  head1Css="metiorite-blue"
                  buttonText="See product information"
                  buttonUrl="/baby/diaperrash/aquaphordiaperrashcream"
                  className="center-v"
                >
                  Helps prevent, soothe, and treat mild to moderate diaper rash
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Aquaphor® Baby 3-in-1 Diaper Rash Cream"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Aquaphor<sup>&reg;</sup> Baby Diaper Rash Paste`}
                  head1Css="metiorite-blue"
                  buttonText="See product information"
                  buttonUrl="/baby/diaperrash/aquaphordiaperrashpaste"
                  className="center-v"
                >
                  Helps effectively treat moderate to severe diaper rash
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Aquaphor® Baby Diaper Rash Paste"
                  className="cout-product-img"
                />
              </Col>
            </Row>
            <p className="references first">
              <span className="font-bold">Reference: 1.</span> Data on file.
              Beiersdorf Inc.
            </p>
          </div>
        </section>
        <section className="callouts-container gradient-bg section-multipurpose-solution">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={12} lg={6} order={{ xs: 2, lg: 1 }} className="">
                <TypeC
                  prodLabel={`MULTIPURPOSE SOLUTION`}
                  prodLabelCss="h3-boxed metiorite-blue"
                  buttonText="AQUAPHOR<sup>®</sup> BABY HEALING OINTMENT"
                  buttonUrl="/baby/generalcare/aquaphorbabyhealingointment"
                  className="center-v"
                >
                  <p className="reg-text-big reg-text-big-only">
                    Helps prevent diaper rash with every diaper change
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={12} lg={6} order={{ xs: 1, lg: 2 }} className="">
                <img
                  src={img3}
                  alt="Aquaphor Baby Healing Product"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Versatitlity of Baby Skin Care Products"
                  caption="Discover the versatility of Aquaphor Baby skin care products"
                  buttonText="GENERAL CARE SOLUTIONS"
                  buttonUrl="/baby/generalcare"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="ECZEMA REGIMEN"
                  caption="Choose a complete routine for eczema with Eucerin<sup>&reg;</sup> Baby"
                  buttonText="ECZEMA REGIMEN"
                  buttonUrl="/baby/eczema"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Resources for Baby Care"
                  caption="Find resources to support your baby care recommendations"
                  buttonText="Browse Resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 125 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DiaperRashPage;
